.App {
  text-align: center;
}



:root {
  --background: #E5E1DA;
  --wheat: #FBF9F1;
  --main: #8E7AB5;
  --trim: #B784B7;
  --pink: #E493B3;
  --orange: #EEA5A6;

  --layer1: #070F2B;
  --layer2: #1B1A55;
  --layer3: #535C91;
  --layer4: #9290C3;

  --popup1: #272829;
  --popup2: #61677A;
  --popup3: #D8D9DA;
  --popup4: #FFF6E0;


  --text: rgb(0, 0, 0);
  --input: rgba(70, 70, 70, 0.521);
  --error: 	#af1818;
  --errorHover: #AA3F3F;
  --success: #4caf50;
  --successHover: #58c75c;
  --nil: rgb(190, 28, 231);
  --invis: rgba(0, 0, 0, 0);

  
}

body, .App {
  overflow: hidden; /* Hide scrollbars */
  background-color: var(--layer1); /* Change background color here */
}

.App-header {
  background-color: var(--layer3);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}



.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: var(--layer2);
  color: white;
  width: 100%;
  height: 5vw;
  box-sizing: border-box;
}

.header-button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 1.5% 2.5%; /* Adjust padding based on percentage of the button size */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2vw; /* Responsive font size based on viewport width */
  margin: 0 1%; /* Adjust margin based on percentage of the button size */
  transition-duration: 0.4s;
  cursor: pointer;
}

.header-button:hover {
  background-color: white;
  color: black;
}

/* Media Query for smaller screens */
@media screen and (max-width: 600px) {
  .header-button {
    padding: 3% 5%;
    font-size: 3vw;
    margin: 0 2%;
  }
}

/* CSS */
.button-85 {

  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;


  
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  padding: 1.5% 2.5%; /* Adjust padding based on percentage of the button size */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2vw; /* Responsive font size based on viewport width */
  margin: 0 1%; /* Adjust margin based on percentage of the button size */
  transition-duration: 0.4s;

  

}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}



.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.61);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sellPopup {
  top: 3vw;
  background-color: var(--popup1);
  padding: 5vw; /* Adjust padding based on viewport width */
  border-radius: 5px;
  width: 50vw; /* Default width */
  max-width: 420px; /* Limit maximum width */
  height: auto; /* Automatically calculate height based on width */
  max-height: calc(90vh - 10vw); /* Limit maximum height relative to viewport width */
  position: relative; /* Ensure absolute positioning of close button is relative to popup */
}
.sellPopup:before {
  content: "";
  display: block;
  padding-top: 100%; /* Maintain 1:1 aspect ratio (height = width) */
}

.popup {
  top: 3vw;
  background-color: var(--popup1);
  padding: 5vw; /* Adjust padding based on viewport width */
  border-radius: 5px;
  width: 50vw; /* Default width */
  max-width: 420px; /* Limit maximum width */
  height: auto; /* Automatically calculate height based on width */
  max-height: calc(90vh - 10vw); /* Limit maximum height relative to viewport width */
  position: relative; /* Ensure absolute positioning of close button is relative to popup */
}

.popup:before {
  content: "";
  display: block;
  padding-top: 100%; /* Maintain 1:1 aspect ratio (height = width) */
}
.popupImage {
  position:absolute;
  top: 2.5%;
  left: 2.5%;
  background-color: var(--popup4);
  border-radius: 5px;
  width: 95%;
  height: 95%; /* Maintain square shape */

}
.popupTitle {

  
  font-family: "Freeman", sans-serif;
  font-weight: 600;
  font-style: normal;

  color: var(--layer1);
  text-shadow: 2px 2px 4px var(--layer3); 

  position:absolute;
  top: 2.5%;
  left: 5%;
  background-color: var(--invis);
  border-radius: 5px;
  width: 90%;
  height: 25%; /* Maintain square shape */

}

.popupFooter {

  
  font-family: "Freeman", sans-serif;
  font-weight: 600;
  font-style: normal;

  color: var(--layer1);
  text-shadow: 2px 2px 4px var(--layer3); 


  position:absolute;
  bottom: 2.5%;
  left: 5%;
  background-color: var(--invis);
  border-radius: 5px;
  width: 90%;
  height: 25%; /* Maintain square shape */

}


/* Media Query for smaller screens */
@media screen and (max-height: 1000px) {
  .popup {
    max-height: calc(80vh - 10vw); /* Adjust maximum height for smaller screens */
  }
}




/* CSS */
.button-5 {



  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;



  position: absolute;


  background-color: var(--invis);
  top: -15%; /* Adjust top distance based on percentage of popup height */
  right: -15%; /* Adjust right distance based on percentage of popup width */


  font-size: 2vw; /* Adjust font size based on viewport width */

  scale: 1;
  vertical-align: baseline;
  width: 7vw;

  transition-duration: .35s;

}

.button-5:hover,
.button-5:focus {
  scale: 1.2;
}


.button-5:active {
  scale: 1.3;
}











/* CSS */
.notification {



  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;



  position: absolute;


  background-color: var(--invis);
  bottom: 0.5vw; /* Adjust top distance based on percentage of popup height */
  left: 0.5vw; /* Adjust right distance based on percentage of popup width */


  font-size: 2vw; /* Adjust font size based on viewport width */

  scale: 1;
  vertical-align: baseline;
  width: 3vw;

  transition-duration: .35s;

}

.notification:hover,
.notification:focus {
  scale: 1.2;
}


.notification:active {
  scale: 1.3;
}





/* CSS */
.button-68 {


  
  position: absolute;


  top: 105%; /* Adjust top distance based on percentage of popup height */
  right: 25%; /* Adjust right distance based on percentage of popup width */

  width: 50%;

  font-size: 2vw; /* Adjust font size based on viewport width */






    
  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;




  
  flex: 1;


  font-size: 1rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;



}

.button-68:hover {
  background-color: #0056b3;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.button-68:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.button-68:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}


.logo {


  padding: 1.5% 2.5%; /* Adjust padding based on percentage of the button size */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2vw; /* Responsive font size based on viewport width */
  margin: 0 1%; /* Adjust margin based on percentage of the button size */



  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;




  border-radius: 5px;
  height:4vw;
  width:12vw;
  cursor: pointer;
  scale: 1;
  transition: ease-in-out 0.25s;


  background-color: var(--invis);
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2vw; /* Responsive font size based on viewport width */
  margin: 0 1%; /* Adjust margin based on percentage of the button size */
  transition-duration: 0.4s;
  cursor: pointer;
  

}

.logo:hover {
  scale: 1.25;
}
.logo:active {
  scale: 1.4;
}






.wallet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100vh - 5vw); /* Adjust height to account for the header */
}

.wallet-content {
  width: 97.5%;
  height: 95%;
  background: var(--invis);
  display: flex;
  align-items: center;
  justify-content: center;
}



.wallet-left-content {
  width: 67.5%; /* Adjust width as necessary */
  background: #e0e0e0; /* Placeholder background */


  
}

.wallet-right-content {
  background: var(--invis); /* Placeholder background */
  width: 32.5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.wallet-avatar {
  width: 100%;
  max-height: 30%;
  background: var(--invis); /* Placeholder background for avatar */

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.wallet-avatar img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%; /* Make the avatar image circular */
}


.wallet-user-details {
  width: 100%;
  background: var(--invis); /* Placeholder background for user details */
  padding: 10px; /* Add some padding */
  box-sizing: border-box; /* Include padding in the width calculation */
}


.wallet-rank {
  margin-top: -4%;
  background: var(--invis); /* Placeholder background for user details */
  width: 100%;
  scale: 1;
  transition-duration: .4s;
  cursor: pointer;
}

.wallet-rank:hover {
  scale: 1.1;

}
.wallet-rank:active {
  scale: 1.15;
}



.wallet-menu-button {
  margin-top: 0%;
  background: var(--invis); /* Placeholder background for user details */
  width: 100%;
  max-height: 64px;
  transition-duration: .4s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}



.wallet-menu-button:hover {
  scale: 1.1;

}
.wallet-menu-button:active {
  scale: 1.15;
}


.wallet-info {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */
  width: 100%; /* Ensure it takes full width */

  color: var(--main);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */


  position: absolute;
  top: 20%;
  left: 5%;
  width: 90%;

  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */

}


.wallet-input {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */

  border: 0px;
  color: var(--text);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */

  background-color: var(--input);

  position: absolute;
  width: 60%;
  left: 5%;
  height: 70%;
  top: 15%;

  z-index: 0;


  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */

}
.wallet-input::placeholder {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */
  color:  var(--text); /* Change this to your desired color */
  opacity: 1; /* Optional: Ensures the placeholder color is fully opaque */
}




.wallet-box {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */

  background-color: var(--main);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */


  position: absolute;
  width: 80%;
  height: 20%;

  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */

}








.transfer-balance {
  position: relative;

  margin-top: -80%;
  z-index: 3;
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */
  width: 100%; /* Ensure it takes full width */

  height: 20%;


  left: 0;

  background-color: var(--error);

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */
}






.wallet-balance {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  background-color: var(--invis);
  font-size: clamp(1rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */
  width: 100%; /* Ensure it takes full width */

  height: 20%;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */
}




.wallet-button {

  
  font-family: "Freeman", sans-serif;
  font-weight: 400;
  font-style: normal;


  z-index: 2;
  
  flex: 1;

  width:75%;
  font-size: 1rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-top: 1%;
  margin-bottom:  1%;
  transition: background-color 0.3s ease;
}

.wallet-button:hover {
  background-color: #0056b3;
}



.wallet-footer-image {
  position: absolute;
  bottom: 3%;
  left: 5%;
  width: 90%;

  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */
}



.wallet-header-image {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;

  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */
}



.wallet-button-container {
  position: absolute;
  top: 10%;
  left: -5%;


  justify-content: space-between;
  align-items: center;
  width: 100%; /* Adjust width as needed */
  height: 20%;
  padding: 5%; /* Optional: add some padding */
}

.wallet-custom-button {



  border: none;



  cursor: pointer;
  width: 30%; /* Adjust width as needed */
  height: 35%;

  margin-left:5%;
  margin-right:5%;

  scale: 1;
  transition-duration: 0.4s;
}

.wallet-custom-button:hover {
  scale: 1.2;

}

.wallet-custom-button:active {
  scale: 1.3;

}




.wallet-custom-button-2 {
  border: none;
  cursor: pointer;
  scale: 1;
  transition-duration: 0.4s;
}
.wallet-custom-button-2:hover {
  scale: 1.2;

}
.wallet-custom-button-2:active {
  scale: 1.3;
}





.wallet-swap-button {


  position: absolute;

  border: none;


  cursor: pointer;
  width: 15%; /* Adjust width as needed */
  height: auto;
  left: 5%;
  top: 50%;


  scale: 1;
  transition-duration: 0.4s;
}

.wallet-swap-button:hover {
  scale: 1.2;

}

.wallet-swap-button:active {
  scale: 1.3;

}





.wallet-box-token {


  position: absolute;

  border: none;

  width: 10%; /* Adjust width as needed */
  height: auto;
  left: 2.5%;
  top: 10%;

  z-index: 1;

  scale: 1;
  transition-duration: 0.4s;
}


.wallet-box-info {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(0.5rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */


  color: var(--layer1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */

  background-color: var(--invis);

  z-index: 1;
  position: absolute;
  top: 40%;
  left: 70%;
  width: 27.5%;


  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */

}

.wallet-box-max-button {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(0.5rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */
  color: var(--layer1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */

  background-color: var(--invis);

  z-index: 1;
  position: absolute;
  top: 10%;
  left: 75%;
  width: 20%; /* Adjust width based on viewport width */
  max-width: 120px; /* Set a maximum width */
  height: auto; /* Adjust height based on viewport height */
  max-height: 40px; /* Set a maximum height */
  transition-duration: 0.4s;

  /* Flexbox for centering text */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}



.wallet-box-max-button:hover {
  scale: 1.2;

}

.wallet-box-max-button:active {
  scale: 1.3;

}















.wallet-transfer-plank {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(0.5rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */


  color: var(--layer1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */

  background-color: var(--invis);

  z-index: 1;
  position: absolute;
  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  object-position: top; /* Align the image to the top */

}


















.duel-box {
  background-color: #5aa6e6;
  border-radius: 10px;
  height: 80%; /* Adjust the height accordingly */
  width: 95%;
  position: absolute;
  top: 2.5%;
  left: 2.5%;
}



.duel-container {
  width: 90%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto; /* Adjust as needed */
}

.duel-item {
  display: flex;
  justify-content: space-between; /* Add this line to evenly space the content */
  align-items: center;
  margin-top: 1%;
  margin-left: 1%;
  width: 98%;
  margin-bottom: 10px;
  background-color: #3498db;
  border-radius: 10px;
  color: #fff;
}
.duel-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.duel-info {
  margin: 0;
}


.setup-description5 {

  color: var(--text);


  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(0.5rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */


}

.scrollable-content {
  position: inherit;
  top: 0%;

  background-color: var(--layer2);
  overflow-y: auto;
  height: 70%;

  width: 100%;
  padding: 10px; /* Add padding for space between content */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

.scrollable-content::-webkit-scrollbar {
  width: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


.duel-content {
  width: 67.5%; /* Adjust width as necessary */
  background-color: var(--invis); /* Placeholder background */


  top: 0%;
  height: 100%; /* or any specific height you want for the parent container */

  display: flex;
  justify-content: center;
  align-items: center;



}




.duel-title {
  position: absolute;
  top: 15%;
  background: var(--invis); /* Placeholder background for user details */
  width: 67.5%;
  scale: 0.9;
  transition-duration: .4s;
  cursor: pointer;
}

.duel-title:hover {
  scale: 0.95;

}
.duel-title:active {
  scale: 0.975;
}



.duel-footer{
  position: absolute;
  bottom: 5%;
  background: var(--invis); /* Placeholder background for user details */
  width: 67.5%;
  scale: 0.9;
  transition-duration: .4s;
  cursor: pointer;
}

.duel-footer:hover {
  scale: 0.95;

}
.duel-footer:active {
  scale: 0.975;
}


.duel-accept-button {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(0.5rem, 2.5vw, 2rem); /* Scale font size responsively */
  text-align: center; /* Center the text horizontally */
  line-height: 1.2; /* Set line height for better readability */
  color: var(--layer1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add drop shadow to text */

  background-color: var(--invis);

  z-index: 1;

  top: 10%;
  left: 75%;
  width: 20%; /* Adjust width based on viewport width */
  max-width: 120px; /* Set a maximum width */
  height: auto; /* Adjust height based on viewport height */
  max-height: 40px; /* Set a maximum height */
  transition-duration: 0.4s;

  /* Flexbox for centering text */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}



.duel-accept-button:hover {
  scale: 1.2;

}

.duel-accept-button:active {
  scale: 1.3;

}